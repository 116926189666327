


import ShareBlock from "../Partials/ShareBlock"

export default function SpreadTheWord() {
    return (
        <>
            <h1 className="white-text m-h400 "> Do your part by sharing our mission with your friends and colleagues</h1>
            <ShareBlock title="Share ScrubsOff"/>
            
           
        </>
    )
}
